import { createTheme, Theme, ThemeProvider } from "@material-ui/core"
import { FunctionComponent } from "react"

type RadiusValue = "normal" | "large"

export const getRadius = (radius: RadiusValue) => {
  switch (radius) {
    case "large":
      return 8
    case "normal":
      return 4
  }
}

export const createSmallRadiusTheme = (theme: Theme, props: SetRadiusProps) => {
  return createTheme({
    ...theme,
    shape: {
      borderRadius: getRadius(props.radius),
    },
  })
}

export interface SetRadiusProps {
  radius: RadiusValue
}

export const RadiusProvider: FunctionComponent<SetRadiusProps> = (props) => {
  return (
    <ThemeProvider
      theme={(theme) => createSmallRadiusTheme(theme as Theme, props)}
    >
      {props.children}
    </ThemeProvider>
  )
}
