import * as CSS from "csstype"
import React, { FunctionComponent } from "react"
import { ImageSource } from "../../interfaces/image-source.interface"
import {
  BackgroundFilterProps,
  BackgroundProps,
  getBackgroundProps,
  SecondBackgroundLayerProps,
} from "./withBackground"

export interface BackgroundImageProps extends BackgroundFilterProps {
  url: ImageSource
  gradient?: string
  color: CSS.Property.BackgroundColor
  position?: CSS.Property.BackgroundPosition<number>
}

export const getBackgroundImageProps = ({
  url,
  color,
  blackAndWhite,
  brightness,
  blur,
  gradient,
  position,
}: BackgroundImageProps) => {
  const secondLayer: SecondBackgroundLayerProps = {}

  if (gradient) {
    secondLayer.image = gradient
  }

  const backgroundProps: BackgroundProps = {
    firstLayer: {
      image: `url(${url})`,
      color,
      position,
      filter: {
        blackAndWhite,
        brightness,
        blur,
      },
    },
    secondLayer,
  }

  return getBackgroundProps(backgroundProps)
}

export const withBackgroundImage =
  <P extends {}>(
    Component: React.ComponentType<P>
  ): FunctionComponent<P & BackgroundImageProps> =>
  ({
    url,
    color,
    blackAndWhite,
    brightness,
    blur,
    gradient,
    position,
    ...props
  }: BackgroundImageProps) => {
    const backgroundProps = getBackgroundImageProps({
      url,
      color,
      blackAndWhite,
      brightness,
      blur,
      gradient,
      position,
    })

    return <Component {...backgroundProps} {...(props as P)} />
  }
