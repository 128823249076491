import { Box, BoxProps } from "@material-ui/core"
import { FunctionComponent } from "react"

export interface FlexBoxProps extends BoxProps {
  center?: boolean
  fullHeight?: boolean
  bgcolor?: string
}

export const FlexBox: FunctionComponent<FlexBoxProps> = ({
  center = false,
  fullHeight = false,
  ...props
}) => {
  if (center) {
    props.justifyContent = "center"
    props.alignItems = "center"
  }

  if (fullHeight) {
    props.minHeight = "100vh"
  }

  return <Box display="flex" flexDirection="column" {...props} />
}
