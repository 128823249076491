import Color from "color"
import { parse, stringify } from "css-box-shadow"

function changeShadowElementColor(
  shadowElement: Record<string, any>,
  color: string
) {
  const shadowColor = Color(shadowElement.color)
  const themeColor = Color(color)
  const newColor = themeColor.alpha(shadowColor.alpha())

  const newShadowElement = {
    ...shadowElement,
    color: newColor.rgb(),
  }

  return newShadowElement
}

export default function changeShadowColor(shadow: string, color: string) {
  const shadowElements = parse(shadow)

  return stringify(
    shadowElements.map((shadowElement: Record<string, any>) =>
      changeShadowElementColor(shadowElement, color)
    )
  )
}
