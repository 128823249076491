import { makeStyles } from "@material-ui/core/styles"
import * as CSS from "csstype"
import { isEmpty, isUndefined } from "lodash-es"
import React, { FunctionComponent } from "react"

const BLUR_PIXELS_DEFAULT = 5

export interface SharedBackgroundLayerProps {
  color?: CSS.Property.BackgroundColor
  image?: CSS.Property.BackgroundImage
  size?: CSS.Property.BackgroundSize<number>
  position?: CSS.Property.BackgroundPosition<number>
}

export interface BackgroundFilterProps {
  blur?: number | boolean
  brightness?: number
  blackAndWhite?: boolean
}

export interface FirstBackgroundLayerProps extends SharedBackgroundLayerProps {
  filter?: BackgroundFilterProps
}

export interface SecondBackgroundLayerProps extends SharedBackgroundLayerProps {
  blendMode?: CSS.Property.MixBlendMode
  opacity?: CSS.Property.Opacity
}

export interface BackgroundProps {
  firstLayer?: FirstBackgroundLayerProps
  secondLayer?: SecondBackgroundLayerProps
}

const isBlurred = ({ firstLayer }: BackgroundProps) => {
  const blur = firstLayer?.filter?.blur
  return !isUndefined(blur) && blur !== false
}

const blurAmount = ({ firstLayer }: BackgroundProps): number => {
  const blur = firstLayer?.filter?.blur
  if (!isBlurred({ firstLayer })) {
    return 0
  }

  return blur === true ? BLUR_PIXELS_DEFAULT : (blur as number)
}

const blurOffset = (props: BackgroundProps) => -2 * blurAmount(props)

const getOverflow = (props: BackgroundProps) =>
  isBlurred(props) ? "hidden" : "visible"

const createFilter = (props: BackgroundProps) => {
  const filter = props?.firstLayer?.filter
  let filterString = ""

  if (filter?.blackAndWhite) {
    filterString += "grayScale(100%) "
  }

  if (!isUndefined(filter?.brightness)) {
    filterString += `brightness(${(filter?.brightness ?? 1) * 100}%) `
  }

  const blurPixels = blurAmount(props)
  if (blurPixels !== 0) {
    filterString += `blur(${blurPixels}px) `
  }

  return filterString ? filterString : undefined
}

const contentIfNotEmpty = (layer: SharedBackgroundLayerProps | undefined) =>
  isEmpty(layer) ? "" : "''"

const firstLayerContent = ({ firstLayer }: BackgroundProps) =>
  contentIfNotEmpty(firstLayer)

const secondLayerContent = ({ secondLayer }: BackgroundProps) =>
  contentIfNotEmpty(secondLayer)

const getFirstLayerProp =
  <TKey extends keyof FirstBackgroundLayerProps>(
    prop: TKey,
    fallback?: FirstBackgroundLayerProps[TKey]
  ) =>
  ({ firstLayer }: BackgroundProps) =>
    firstLayer?.[prop] ?? fallback

const getSecondLayerProp =
  <TKey extends keyof SecondBackgroundLayerProps>(
    prop: TKey,
    fallback?: SecondBackgroundLayerProps[TKey]
  ) =>
  ({ secondLayer }: BackgroundProps) =>
    secondLayer?.[prop] ?? fallback

// @ts-ignore | Seems to be a bug caused by overflow being a function
const useStyles = makeStyles({
  root: {
    position: "relative",
    overflow: getOverflow,

    "&:before": {
      content: firstLayerContent,
      position: "absolute",
      left: blurOffset,
      top: blurOffset,
      right: blurOffset,
      bottom: blurOffset,
      zIndex: -2,
      backgroundColor: getFirstLayerProp("color", "transparent"),
      backgroundImage: getFirstLayerProp("image"),
      backgroundSize: getFirstLayerProp("size", "cover"),
      backgroundPosition: getFirstLayerProp("position", "center"),
      filter: createFilter,
    },

    "&:after": {
      content: secondLayerContent,
      position: "absolute",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: -1,
      backgroundColor: getSecondLayerProp("color", "transparent"),
      backgroundImage: getSecondLayerProp("image"),
      backgroundSize: getSecondLayerProp("size", "cover"),
      backgroundPosition: getSecondLayerProp("position", "center"),
      opacity: getSecondLayerProp("opacity", 1.0),
    },
  },
})

export const getBackgroundProps = (settings: BackgroundProps) => ({
  className: useStyles(settings).root,
})

export const withBackground =
  <P extends {}>(
    Component: React.ComponentType<P>
  ): FunctionComponent<P & BackgroundProps> =>
  ({ firstLayer, secondLayer, ...props }: BackgroundProps) => {
    const backgroundProps = getBackgroundProps({ firstLayer, secondLayer })

    return <Component {...backgroundProps} {...(props as P)} />
  }
