import {
  ButtonProps,
  CircularProgress,
  makeStyles,
  Theme,
  useTheme,
  WithTheme,
} from "@material-ui/core"
import clsx from "clsx"
import React, { forwardRef, FunctionComponent, ReactElement } from "react"
import changeShadowColor from "../../utils/changeShadowColor"
import { Button } from "./Button"

export const boxShadowMethodForDepth =
  (depth: number) =>
  ({
    theme,
    shadowColor,
    color = "default",
  }: StyledButtonProps & WithTheme) => {
    if (color === "inherit" || color === "default") {
      return null
    }

    const shadow = theme.shadows[depth]
    const calculatedShadowColor = shadowColor ?? theme.palette[color].main

    return changeShadowColor(shadow, calculatedShadowColor)
  }

const useStyles = makeStyles<Theme, StyledButtonProps & WithTheme>({
  glow: {
    boxShadow: boxShadowMethodForDepth(8),
    transition: "all .2s ease-in-out",

    "&:hover": {
      boxShadow: boxShadowMethodForDepth(16),
      transform: "scale(1.01)",
    },
  },
})

export interface StyledButtonProps extends ButtonProps {
  loading?: boolean
  shadowColor?: string
}

const ButtonContent: FunctionComponent<StyledButtonProps> = ({
  loading,
  children,
}): ReactElement => {
  if (loading) {
    // TODO: Replace size with line height
    return <CircularProgress color="inherit" size={26} thickness={5} />
  }

  return <React.Fragment>{children}</React.Fragment>
}

export const StyledButton: FunctionComponent<StyledButtonProps> = forwardRef(
  ({ loading, children, startIcon, shadowColor, ...props }, ref) => {
    const theme = useTheme()
    const classes = useStyles({ theme, shadowColor, ...props })

    const loadingCorrectedStartIcon = loading ? null : startIcon

    return (
      <Button
        {...props}
        startIcon={loadingCorrectedStartIcon}
        className={clsx(classes.glow, props.className)}
        ref={ref}
      >
        <ButtonContent loading={loading}>{children}</ButtonContent>
      </Button>
    )
  }
)
