import { Button as MaterialButton, ButtonProps } from "@material-ui/core"
import { forwardRef, FunctionComponent } from "react"
import { RadiusProvider } from "../../theme"

export const Button: FunctionComponent<ButtonProps> = forwardRef(
  (props, ref) => {
    return (
      <RadiusProvider radius="normal">
        <MaterialButton {...props} ref={ref} />
      </RadiusProvider>
    )
  }
)
