import { ThemeProvider } from "@material-ui/core"
import React from "react"
import LanguageSelector from "../components/localization/LanguageSelector"
import { baseTheme } from "../theme"
import { initGA, logPageView } from "../utils/google-analytics"

declare global {
  interface Window {
    GA_INITIALIZED: boolean
  }
}

export class BaseLayout extends React.Component {
  componentDidMount() {
    if (window && !window.GA_INITIALIZED) {
      initGA()
      window.GA_INITIALIZED = true
    }

    logPageView()
  }

  render() {
    return (
      <React.Fragment>
        <style global jsx>
          {`
            html,
            body,
            div#__next {
              width: 100vw;
              min-height: 100vh;
              position: relative;
            }

            body {
              overflow-x: hidden;
            }

            div#__next {
              display: flex;
              flex-direction: column;
            }
          `}
        </style>
        <ThemeProvider theme={baseTheme}>{this.props.children}</ThemeProvider>
        {process.env.NODE_ENV == "development" && <LanguageSelector />}
      </React.Fragment>
    )
  }
}
