import { forwardRef, FunctionComponent } from "react"
import { StyledButton, StyledButtonProps } from "./StyledButton"

export const SecondaryButton: FunctionComponent<StyledButtonProps> = forwardRef(
  (props, ref) => (
    <StyledButton
      variant="contained"
      color="secondary"
      size="large"
      ref={ref}
      fullWidth
      {...props}
    />
  )
)
