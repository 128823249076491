import { createTheme, Theme, ThemeProvider } from "@material-ui/core"
import { FunctionComponent } from "react"

export const createDarkTheme = (theme: Theme, dark = true) => {
  const {
    common,
    primary,
    secondary,
    error,
    warning,
    info,
    success,
    grey,
    contrastThreshold,
    tonalOffset,
  } = theme.palette

  return createTheme({
    ...theme,
    palette: {
      common,
      primary,
      secondary,
      error,
      warning,
      info,
      success,
      grey,
      contrastThreshold,
      tonalOffset,
      type: dark ? "dark" : "light",
    },
  })
}

export interface DarkThemeProviderProps {
  dark?: boolean
}

export const DarkThemeProvider: FunctionComponent<DarkThemeProviderProps> = (
  props
) => {
  return (
    <ThemeProvider
      theme={(theme) => createDarkTheme(theme as Theme, props.dark)}
    >
      {props.children}
    </ThemeProvider>
  )
}
