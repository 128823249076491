import { createTheme, responsiveFontSizes } from "@material-ui/core"

export const baseTheme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: "#00AD3C",
      },
      secondary: {
        main: "#184B70",
        contrastText: "#ffffff",
      },
      error: {
        main: "#fa0253",
      },
    },
    typography: {
      allVariants: {
        color: "#000000",
      },
      fontFamily: ["Roboto", "sans-serif"].join(","),
      h2: {
        fontWeight: 900,
        fontSize: 55,
      },
      h3: {
        fontWeight: 200,
      },
      h4: {
        fontWeight: 100,
        letterSpacing: 0,
      },
      h5: {
        fontWeight: 700,
      },
      button: {
        letterSpacing: 1,
      },
    },
    shape: {
      borderRadius: 8,
    },
    props: {
      MuiPaper: {
        elevation: 12,
      },
      MuiCard: {
        elevation: 12,
      },
      MuiAccordion: {
        elevation: 1,
      },
    },
    overrides: {
      MuiCard: {
        root: {
          position: "relative",
          overflow: "hidden",
        },
      },
      MuiFormHelperText: {
        root: {
          height: 0,
          marginTop: 0,
          position: "relative",
          top: 3,
        },
      },
    },
  }),
  {
    factor: 2.5,
  }
)
